import React from "react";

export const SummaryCard = ({ data }) => {
  return (
    <div className="bg-gradient-to-b from-white to-gray-50 p-6 rounded-lg shadow-lg 
    flex flex-col items-start border-l-4 border-blue-500 relative h-[75dvh] md:h-full mx-3">
      {/* Icon for a techy feel */}
      <div className="text-blue-500 mb-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 8c1.657 0 3-1.343 3-3S13.657 2 12 2 9 3.343 9 5s1.343 3 3 3zM12 14c-4.418 0-8 1.79-8 4v2h16v-2c0-2.21-3.582-4-8-4z"
          />
        </svg>
      </div>
      <p className="text-blue-700 font-semibold text-lg tracking-wide mb-2">
        Section Summary
      </p>
      <p className="text-gray-800 text-base font-medium mt-4 leading-relaxed">
        {data}
      </p>
      {/* Subtle shadow element to add depth */}
      <div className="absolute inset-0 rounded-lg bg-blue-100 opacity-10 blur-sm"></div>
    </div>
  );
};
