import React, { useLayoutEffect, useRef, useState } from "react";
import { SummaryCard } from "../component/SummaryCard";
import { GoalCard } from "../component/GoalCard";
import { CondensedTranscript } from "../component/CondensedTranscript";
import { StartingCard } from "../component/StartingCard";
import { NoSummaryCard } from "../component/NoSummaryCard";

export const Content = ({
  currentSegment,
  selected,
  setSelected,
  contentItems,
  currentContentIndex,
  setVideoInfos,
  setShowVideoPopup,
  total_steps,
}) => {
  const contentRefs = useRef([]);
  const [currentSeek, setCurrentSeek] = useState(null);

  // Use useLayoutEffect to scroll the current content into view when currentContentIndex changes
  useLayoutEffect(() => {
    const ref = contentRefs.current[currentContentIndex];
    if (ref) {
      ref.scrollIntoView(
        {
          behavior: "smooth",
          block: "center",
        },
        50
      );
    }
  }, [currentContentIndex, selected]);

  const scrollMarginTopClass = "scroll-mt-10";

  if (selected === "Transcripts") {
    return (
      <div className="flex">
        <CondensedTranscript
          condensedTranscript={currentSegment.condensed_transcript}
          setVideoInfos={setVideoInfos}
          setShowVideoPopup={setShowVideoPopup}
          currentSeek={currentSeek}
        />
        <div className="w-full flex justify-center absolute">
          <div
            className="fixed bg-white px-4 py-2 rounded-lg bottom-[10dvh] md:hidden border-2 border-blue-500 text-blue-500 shadow-md cursor-pointer"
            onClick={() => setSelected("Content")}
          >
            Back to Cards
          </div>
        </div>
      </div>
    );
  }

  const handleSeek = (start) => {
    setCurrentSeek(start); // Update the current seek to scroll
    setSelected("Transcripts");
  };

  // Render all content items
  return (
    <div>
      {contentItems.map((contentItem, index) => {
        const { type, data } = contentItem;

        if (type === "Starting") {
          return (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className={`${scrollMarginTopClass} mt-[10dvh] h-[75dvh] md:h-[30dvh] mb-12`}
            >
              <StartingCard />
            </div>
          );
        }

        if (type === "Summary") {
          if (
            !data ||
            data.toLowerCase() === "no summary available for this section."
          ) {
            return (
              <div
                className={`flex mt-[10dvh] items-center justify-center w-full h-[75dvh] ${scrollMarginTopClass}`}
                key={index}
                ref={(el) => (contentRefs.current[index] = el)}
              >
                <NoSummaryCard setSelected={setSelected} />
              </div>
            );
          }
          return (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className={`${scrollMarginTopClass} mt-[10dvh] h-[75dvh] md:h-[30dvh] mb-12`}
            >
              <SummaryCard data={data} />
            </div>
          );
        }

        if (type === "Goal") {
          const goal = data;
          return (
            <div
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              className={`${scrollMarginTopClass} mt-[10dvh] h-[75dvh] md:h-[30dvh] mb-12`}
              onClick={() => handleSeek(data.start)}
            >
              <GoalCard
                total_steps={total_steps}
                step_number={goal.step_number}
                learning_goal={goal.learning_goal}
                explanation={goal.explanation}
              />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};
