export const NoSummaryCard = ({ setSelected, handleCardNext }) => {
  const handleClickEffect = (e) => {
    const button = e.currentTarget;
    const ripple = document.createElement("span");

    // Get button's bounding box and calculate position for ripple
    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = e.clientX - rect.left - size / 2;
    const y = e.clientY - rect.top - size / 2;

    // Style ripple
    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    ripple.className =
      "absolute bg-white opacity-50 rounded-full pointer-events-none animate-ping";

    button.appendChild(ripple);

    // Remove ripple after animation
    setTimeout(() => {
      ripple.remove();
    }, 500);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <div className="flex flex-col justify-center items-center">
        <div className="flex items-center">
          <p className="text-gray-600 font-semibold">
            No Summary Card available for this section
          </p>
          {/* Tooltip Icon */}
          <div className="relative group ml-2">
            <div className="w-5 h-5 bg-gray-300 text-gray-800 rounded-full flex items-center justify-center text-sm font-bold cursor-pointer">
              ?
            </div>
            {/* Tooltip */}
            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:flex bg-black text-white text-xs rounded px-4 py-2 shadow-lg min-w-[200px] z-50">
              The current section is too short or contains too few key points.
            </span>
          </div>
        </div>

        <button
          onClick={(e) => {
            handleClickEffect(e);
            handleCardNext();
          }}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 relative overflow-hidden"
        >
          Go to the next card
        </button>

        {/* Scroll down hint */}
        <p className="text-gray-500 text-sm md:hidden mt-2">
          Scroll up to the next section
        </p>
      </div>

      <div className="fixed bottom-[16dvh] font-medium md:hidden">Or</div>
      <div
        className="fixed bg-white px-4 py-2 rounded-lg bottom-[10dvh] md:hidden border-2 border-blue-500 text-blue-500 shadow-md cursor-pointer"
        onClick={() => setSelected("Transcripts")}
      >
        Go to Transcript
      </div>
    </div>
  );
};
