import React from "react";
import { NoSummaryCard } from "../component/NoSummaryCard";
import { GoalCardDesktop } from "../component/GoalCardDesktop";
import { SummaryCardDesktop } from "../component/SummaryCardDesktop";
import { StartingCardDesktop } from "../component/StartingCardDesktop";
export const DesktopContent = ({
  contentItems,
  currentContentIndex,
  total_steps,
  setIsTranscriptCollapsed,
  handleCardNext
}) => {
  const currentItem = contentItems[currentContentIndex];

  if (!currentItem) {
    return null;
  }

  // Function to handle click and ensure the transcript is visible
  const handleContentClick = () => {
    if (setIsTranscriptCollapsed) {
      setIsTranscriptCollapsed(false); // Ensure transcript is shown
    }
  };

  const { type, data } = currentItem;

  if (type === "Starting") {
    return (
      <div className="w-full flex justify-center mt-2">
        <div className="w-full max-w-3xl flex justify-center">
          <StartingCardDesktop />
        </div>
      </div>
    );
  }

  if (type === "Summary") {
    if (
      !data ||
      data.toLowerCase() === "no summary available for this section."
    ) {
      return (
        <div className="w-full flex justify-center mt-2">
          <div className="w-full max-w-3xl flex justify-center">
            <NoSummaryCard handleCardNext={handleCardNext}/>
          </div>
        </div>
      );
    }
    return (
      <div className="w-full max-h-[80%] flex justify-center mt-2">
        <div className="w-full max-w-3xl flex justify-center">
          <SummaryCardDesktop data={data} />
        </div>
      </div>
    );
  }

  if (type === "Goal") {
    const goal = data;
    return (
      <div className="w-full max-h-[80%] flex justify-center mt-2 hover:cursor-pointer" onClick={handleContentClick}>
        <div className="w-full max-w-3xl flex justify-center">
          <GoalCardDesktop
            total_steps={total_steps}
            step_number={goal.step_number}
            learning_goal={goal.learning_goal}
            explanation={goal.explanation}
          />
        </div>
      </div>
    );
  }

  return null;
};
