import React from "react";
import { secondsToTime } from "../helper";
import { MdOutlineTimeline } from "react-icons/md"; // Better Timeline Icon

export const TimelineSidebar = ({
  segments,
  currentSegmentIndex,
  setCurrentSegmentIndex,
  handleSeek,
  isTimelineCollapsed,
  setIsTimelineCollapsed,
}) => {
  return (
    <div
      className={`transition-all duration-300 ease-in-out ${
        isTimelineCollapsed ? "w-14" : "w-[14rem]"
      } bg-white border-r border-gray-200 h-full flex flex-col`}
    >
      {/* Toggle Button */}
      <div className="flex items-center justify-center h-14 border-b border-gray-200 relative">
        <button
          onClick={() => setIsTimelineCollapsed(!isTimelineCollapsed)}
          className="focus:outline-none flex flex-col items-center space-y-1"
          aria-label={isTimelineCollapsed ? "Expand Timeline" : "Collapse Timeline"}
        >
          <MdOutlineTimeline
            className={`${
              isTimelineCollapsed ? "text-blue-500" : "text-gray-600"
            } text-3xl`}
          />
          {isTimelineCollapsed && (
            <span className="text-xs text-blue-500 font-semibold">Timeline</span>
          )}
        </button>
      </div>
      {/* Timeline Content */}
      {!isTimelineCollapsed && (
        <div className="overflow-y-auto">
          <ul>
            {segments.map((segment, index) => (
              <li
                key={index}
                className={`p-2 cursor-pointer ${
                  currentSegmentIndex === index
                    ? "bg-blue-100"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => {
                  setCurrentSegmentIndex(index);
                  handleSeek(segment.start);
                }}
              >
                <div className="font-semibold">{segment.title}</div>
                <div className="text-sm text-gray-500">
                  {segment.start}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
