import { useEffect, useRef, useState } from "react";
import { ModalWrapper } from "../../components/modal-wrapper";
import Chatbox from "../component/Chatbox";
import { useParams } from "react-router-dom";
import { extractYouTubeID, getSegment, timeInSeconds } from "../helper";
import toastify from "../../../toastify";
import { Loading } from "../../components/Loading";
import WatchPageLayout from "../watchPageLayout";
import { Modal } from "../../components/Modal";
import { MobileWatchTour } from "./MobileWatchTour";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { secondsToTime } from "../helper";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { VideoPopup } from "../component/VideoPopup";
import YouTubePlayer from "../component/YouTubePlayer";
import MobileVideoInfo from "./MobileVideoInfo";

const timeToSeconds = (time) => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const MobileWatch = () => {
  const { id } = useParams();
  const location = useLocation();
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [segments, setSegments] = useState([]);
  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isTimelineModalOpen, setIsTimelineModalOpen] = useState(false);
  const [timeFromChatbot, setTimeFromChatbot] = useState();
  const token = localStorage.getItem("token");
  const [runOnboarding, setRunOnboarding] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [videoId, setVideoId] = useState(null);
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [videoInfos, setVideoInfos] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentSegment, setCurrentSegment] = useState(null);
  const currentSegmentRef = useRef(null);

  const [videoTitle, setVideoTitle] = useState(null);
  const [videoDuration, setVideoDuration] = useState(-1); // this is for handleing progress bar, which is the last start time
  const [videoTrueDuration, setVideoTrueDuration] = useState(-1); // this is the true duration

  const startOnboarding = () => {
    setRunOnboarding(true); // Start onboarding when this function is triggered
  };

  useEffect(() => {
    // Check if videoTitle exists in location.state
    if (location.state && location.state.videoTitle) {
      setVideoTitle(location.state.videoTitle); // Set the videoTitle state
    }
  }, []);

  const navigate = useNavigate();
  // Fetch the segments of the video
  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const res = await getSegment(id, token);
        const data = res.data;

        if (data.segments) {
          setSegments(data.segments);
          setVideoDuration(
            timeToSeconds(data.segments[data.segments.length - 1].start)
          );
        }

        if (data.title) {
          if (data.title !== "No title available") {
            setVideoTitle(data.title);
          }
        }

        if (data.duration) {
          setVideoTrueDuration(data.duration);
        }

        if (data.link) {
          setVideoId(extractYouTubeID(data.link));
        }

        if (data.segments) {
          setDataLoaded(true);
        } else {
          toastify.error(
            "Failed to load video! Please try again or contact us on discord."
          );
          navigate("/");
        }
      } catch (err) {
        toastify.error("Failed to load video, please refresh the page!");
        setDataLoaded(true);
      }
    };

    if (id) fetchSegments();
  }, [id]);

  const memorizedVideoPlayer = (
    <YouTubePlayer
      videoId={videoId}
      initialTime={videoInfos.time}
      onTimeUpdate={(time) => {
        setCurrentTime(time);
      }}
      muted={true}
    />
  );

  useEffect(() => {
    if (!showVideoPopup) {
      return;
    }

    const nextSegment = segments[currentSegmentIndex + 1];

    if (!nextSegment) {
      return;
    }

    const nextSegmentStart = timeInSeconds(nextSegment.start || "0:00:00");

    if (currentTime >= nextSegmentStart) {
      setCurrentSegmentIndex(currentSegmentIndex + 1);
    }
  }, [currentTime, currentSegmentIndex, showVideoPopup]);

  useEffect(() => {
    if (!showVideoPopup) {
      return;
    }

    const currentSegment = segments[currentSegmentIndex];

    if (!currentSegment) {
      return;
    }

    const condensedTranscripts = currentSegment.condensed_transcript.sort(
      (a, b) => a.start - b.start
    );

    let currentTranscript;

    for (let i = condensedTranscripts.length - 1; i >= 0; i--) {
      const transcript = condensedTranscripts[i];
      if (transcript.start <= currentTime) {
        currentTranscript = transcript;
        break;
      }
    }

    if (!currentTranscript) {
      return;
    }

    if (currentTranscript.start !== videoInfos.time) {
      setVideoInfos({
        time: currentTranscript.start,
        content: currentTranscript.content,
      });
    }

    console.log(condensedTranscripts, currentTranscript, currentTime);
  }, [currentSegmentIndex, currentTime, showVideoPopup, videoInfos]);

  // Scroll to the current segment in the timeline modal
  useEffect(() => {
    if (currentSegmentRef.current) {
      currentSegmentRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [isTimelineModalOpen]);

  useEffect(() => {
    if (segments.length > 0) {
      setCurrentSegment(segments[currentSegmentIndex]);
    }
  }, [currentSegmentIndex, segments]);

  // Set the current segment index to the time from the chatbot
  useEffect(() => {
    if (timeFromChatbot) {
      // Sort the segments by start time
      const orderedSegments = segments.sort(
        (a, b) => timeInSeconds(a.start) - timeInSeconds(b.start)
      );

      // Find the index of the segment that starts after the time from the chatbot
      const indexFromChatBot =
        orderedSegments.findIndex(
          (segment) => timeInSeconds(segment.start) > timeFromChatbot
        ) - 1;

      // If the index is -1, set the current segment to the last segment
      if (indexFromChatBot === -1) {
        setCurrentSegmentIndex(orderedSegments.length - 1);
        return;
      }

      setCurrentSegmentIndex(indexFromChatBot);
    }
  }, [timeFromChatbot]);

  // const currentSegment = segments[currentSegmentIndex];
  const title = currentSegment?.title || ""; // Get title from segments

  const TimeLineModal = () => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    return (
      <Modal setOpen={setIsTimelineModalOpen}>
        <div className="relative bg-white p-4 rounded-lg z-30 flex flex-col items-center w-3/4 h-[60%]">
          <button
            onClick={() => setIsTimelineModalOpen(false)}
            className="absolute top-2 right-4 text-gray-600 hover:text-gray-900 font-bold text-xl z-50"
          >
            &times;
          </button>

          {/* Title with Tooltip Icon */}
          <div className="relative w-full flex items-center">
            <h2 className="text-lg font-bold">Choose Timeline</h2>

            {/* FontAwesome Circle Question Icon */}
            <FontAwesomeIcon
              icon={faCircleQuestion}
              className="text-gray-500 ml-2 cursor-pointer"
              onClick={() => setIsTooltipVisible(!isTooltipVisible)} // Toggle tooltip on click
            />

            {/* Tooltip (conditionally rendered) */}
            {isTooltipVisible && (
              <div className="absolute right-0 top-10 bg-gray-100 shadow-lg rounded-md p-2 w-48 text-sm text-gray-700">
                <p>
                  <span className="font-bold text-green-500">Low: </span>
                  Basic or repetitive content
                </p>
                <p>
                  <span className="font-bold text-yellow-500">Med: </span>
                  Moderate concept density
                </p>
                <p>
                  <span className="font-bold text-red-500">High: </span>
                  Highly condensed information
                </p>
              </div>
            )}
          </div>

          <ul className="mt-4 overflow-y-auto w-full">
            {segments.map((segment, index) => {
              const nextTimestamp =
                index < segments.length - 1
                  ? segments[index + 1].start
                  : videoTrueDuration !== -1
                    ? secondsToTime(videoTrueDuration)
                    : "End";

              const rate = segment?.rate; // Get rate from the segment (0, 1, 2, or -1)

              let rateLabel = "";
              let rateColor = "";

              // Assign concise labels and colors based on the rate
              if (rate === 0) {
                rateLabel = "Low";
                rateColor = "bg-green-200"; // Example color for low density
              } else if (rate === 1) {
                rateLabel = "Med";
                rateColor = "bg-yellow-200"; // Example color for medium density
              } else if (rate === 2) {
                rateLabel = "High";
                rateColor = "bg-red-200"; // Example color for high density
              }

              return (
                <li
                  key={index}
                  ref={index === currentSegmentIndex ? currentSegmentRef : null}
                  id={index === 0 ? "timeline" : undefined}
                  className={`cursor-pointer hover:bg-gray-200 p-2 rounded-lg font-semibold ${currentSegmentIndex === index ? "bg-gray-200" : ""}`}
                  onClick={() => {
                    setCurrentSegmentIndex(index);
                    setIsTimelineModalOpen(false);
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div>{segment.title}</div>
                    {/* Only show the label if the rate is not -1 */}
                    {rate !== -1 && (
                      <div className={`px-2 py-1 rounded-lg ${rateColor}`}>
                        {rateLabel}
                      </div>
                    )}
                  </div>
                  <div className="text-sky-600">
                    {segment.start}
                    {" - "}
                    {nextTimestamp}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Modal>
    );
  };

  if (!dataLoaded) {
    return (
      <WatchPageLayout setIsChatbotOpen={setIsChatbotOpen} lightsOn={true}>
        <Loading />
      </WatchPageLayout>
    );
  }

  // Compute start and end times
  const currentSegmentStart = currentSegment?.start || "";

  return (
    <WatchPageLayout
      setIsChatbotOpen={setIsChatbotOpen}
      lightsOn={true}
      videoTitle={videoTitle}
    >
      {showVideoPopup && (
        <VideoPopup
          setOpen={() => {
            setShowVideoPopup(false)
            setCurrentTime(0)
            setVideoInfos(0)
          }}
          memorizedVideoPlayer={memorizedVideoPlayer}
          videoInfos={videoInfos}
        />
      )}
      <div className="w-full h-full flex flex-col relative justify-center pb-3">
        <MobileWatchTour
          setIsTimelineModalOpen={setIsTimelineModalOpen}
          runOnboarding={runOnboarding}
          setRunOnboarding={setRunOnboarding}
          setIsExpanded={setIsExpanded}
          setCurrentSegmentIndex={setCurrentSegmentIndex}
        />
        <MobileVideoInfo
          currentSegment={currentSegment}
          currentSegmentIntro={title}
          segmentsLength={segments.length}
          setCurrentSegmentIndex={setCurrentSegmentIndex}
          currentSegmentIndex={currentSegmentIndex}
          setIsTimelineModalOpen={setIsTimelineModalOpen}
          setIsChatbotOpen={setIsChatbotOpen}
          currentSegmentStart={currentSegmentStart}
          startOnboarding={startOnboarding}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          setVideoInfos={setVideoInfos}
          setShowVideoPopup={setShowVideoPopup}
          videoDuration={videoDuration}
          segments={segments}

        />
        {/* Chat box Modal  */}
        {isChatbotOpen && (
          <ModalWrapper setOpen={setIsChatbotOpen}>
            <Chatbox
              _id={id}
              currentSegment={currentSegment}
              close={() => setIsChatbotOpen(false)}
              setTimeFromChatbot={setTimeFromChatbot}
              currentTime={currentSegment ? timeToSeconds(currentSegment.start) : 0}
            />
          </ModalWrapper>
        )}
        {/* Timeline Modal */}
        {isTimelineModalOpen && <TimeLineModal />}
      </div>
    </WatchPageLayout>
  );
};
