// YouTubePlayer.jsx
import React, { useEffect, useRef, useState } from "react";

const YouTubePlayer = ({
  videoId,
  onTimeUpdate,
  seekTo,
  seekToTrigger,
  initialTime,
  onPlayerLoaded,
  muted = false,
}) => {
  const playerRef = useRef(null);
  const intervalIdRef = useRef(null);
  const [playerReady, setPlayerReady] = useState(false);

  useEffect(() => {
    const loadYouTubeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      } else {
        initializePlayer();
      }

      window.onYouTubeIframeAPIReady = () => {
        initializePlayer();
      };
    };

    const initializePlayer = () => {
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }

      setPlayerReady(false); // Reset player ready state

      playerRef.current = new window.YT.Player("player", {
        height: "100%",
        width: "100%",
        videoId: videoId,
        playerVars: {
          rel: 0,
          autoplay: 0,
          controls: 1,
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    };

    if (videoId) {
      loadYouTubeAPI();
    }

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }
      setPlayerReady(false); // Reset player ready state on unmount
    };
  }, [videoId]);

  useEffect(() => {
    if (playerReady && seekTo !== undefined && playerRef.current) {
      playerRef.current.seekTo(seekTo, true);
    }
  }, [playerReady, seekToTrigger]); // Removed seekTo from dependencies

  function onPlayerReady(event) {
    setPlayerReady(true);

    // Notify the parent component that the player has finished loading
    if (onPlayerLoaded) {
      onPlayerLoaded();
    }

    // Update current video interval
    intervalIdRef.current = setInterval(() => {
      if (playerRef.current && typeof onTimeUpdate === "function") {
        const currentTime = playerRef.current.getCurrentTime();
        onTimeUpdate(currentTime);
      }
    }, 1000);

    // Seek to the initial time if provided
    if (initialTime !== undefined) {
      playerRef.current.seekTo(initialTime, true);
    }

    // Mute the video if necessary
    if (muted) {
      playerRef.current.mute();
    }
  }

  function onPlayerStateChange(event) {
    if (event.data === window.YT.PlayerState.PLAYING) {
      // Logic when playing
    }
  }

  return (
    <div
      id="player"
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
      }}
    ></div>
  );
};

export default YouTubePlayer;
