import React, { useEffect, useState } from "react";
import { dotSpinner } from "ldrs";

import { MobileWatch } from "./mobile/mobile-watch";
import { DesktopWatchSimple } from "./desktop/DesktopWatchSimple";
import { DesktopWatchDetailed } from "./desktop/DesktopWatchDetailed";

import ChoiceModal from "./ChoiceModal";

dotSpinner.register();

const Watch = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 980);
  const [selectedVersion, setSelectedVersion] = useState("simple");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 980);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Whenever selectedVersion changes, store it in localStorage
  useEffect(() => {
    if (selectedVersion) {
      localStorage.setItem("selectedVersion", selectedVersion);
    }
  }, [selectedVersion]);

  if (isMobile) {
    return <MobileWatch />;
  }

  // If the user hasn't selected a version yet, show the choice modal
  if (!selectedVersion) {
    return <ChoiceModal setSelectedVersion={setSelectedVersion} />;
  }

  // Render the selected version, passing down a function to toggle versions
  if (selectedVersion === "simple") {
    return (
      <DesktopWatchSimple
        toggleVersion={() => setSelectedVersion("detailed")}
      />
    );
  }

  if (selectedVersion === "detailed") {
    return (
      <DesktopWatchDetailed
        toggleVersion={() => setSelectedVersion("simple")}
      />
    );
  }

  // Fallback (should not reach here)
  return null;
};

export default Watch;
