import React, { useEffect, useRef, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faComments,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft, faArrowRight, faArrowUp, faArrowDown, faKeyboard } from "@fortawesome/free-solid-svg-icons";
import { DesktopContent } from "./DesktopContent";
import toastify from "../../../toastify";
import { timeInSeconds } from "../helper";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import ChatbotLogo from "./ChatbotLogo.png";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { feedbackFetch } from "../../login-page/helper";
import { CreditsContext } from "../../header/CreditsContext";

export const DesktopVideoInfo = (props) => {
  const {
    currentSegment,
    segments,
    segmentsLength,
    currentSegmentIndex,
    setCurrentSegmentIndex,
    setIsChatbotOpen,
    startOnboarding,
    handleSeek,
    toggleVersion,
    currentTime,
    contentItems,
    setContentItems,
    currentContentIndex,
    setCurrentContentIndex,
    isTimelineCollapsed,
    setIsTimelineCollapsed,
    isTranscriptCollapsed,
    setIsTranscriptCollapsed,
    source
  } = props;
  const [showHint, setShowHint] = useState(false);
  const [hintDisplayed, setHintDisplayed] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [feedbackSubmitting, setFeedbackSubmitting] = useState(false);
  const { fetchCredits } = useContext(CreditsContext);
  const token = localStorage.getItem("token");

  const handleHideButton = () => {
    setShowButton(false);
  };

  const handleShowButton = () => {
    setShowButton(true);
  };
  
  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setFeedback(""); // Clear feedback on close
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };
  
  const handleFeedbackSubmit = async () => {
    setFeedbackSubmitting(true);
    const wordCount = feedback.trim().split(/\s+/).length;

    if (wordCount <= 1) {
      toastify.error("Please share a bit more detail.");
    } else {
      try {
        const res = await feedbackFetch(token, feedback);

        if (res.status === 200) {
          toastify.custom("❤️ Thank you for submitting feedback!");
          setFeedback(""); // Clear the feedback input
          fetchCredits();
        } else {
          toastify.error(res.data.detail);
        }
      } catch (error) {
        console.error("Error submitting feedback:", error);
        toastify.error(
          "An error occurred while submitting feedback! Please try again later."
        );
      }
    }

    setFeedbackSubmitting(false);
  };

  // Auto-hide the hint after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => setShowHint(false), 3000); // Show for 3 seconds
    return () => clearTimeout(timer);
  }, []);

  // Ref to track manual navigation
  const isManualNavigation = useRef(false);

  const handleClickEffect = (e) => {
    const button = e.currentTarget;
    const ripple = document.createElement("span");
  
    // Get button dimensions and calculate ripple position
    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = e.clientX - rect.left - size / 2;
    const y = e.clientY - rect.top - size / 2;
  
    // Style the ripple
    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    ripple.className =
      "absolute bg-white opacity-50 rounded-full pointer-events-none animate-ping";
  
    button.appendChild(ripple);
  
    // Remove ripple after animation
    setTimeout(() => {
      ripple.remove();
    }, 500);
  };
  
  // Show the hint for 3 seconds on first interaction
  const triggerHint = () => {
    if (!hintDisplayed) {
      setShowHint(true);
      setHintDisplayed(true); // Mark hint as displayed
      setTimeout(() => setShowHint(false), 3000); // Hide hint after 3 seconds
    }
  };

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePrev();
      } else if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowUp") {
        if (isTimelineCollapsed) {
          setIsTimelineCollapsed(false); // Expand timeline sidebar on first vertical navigation
        }
        handlePrevSection(); // Navigate to the previous section
      } else if (event.key === "ArrowDown") {
        if (isTimelineCollapsed) {
          setIsTimelineCollapsed(false); // Expand timeline sidebar on first vertical navigation
        }
        handleNextSection(); // Navigate to the next section
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentContentIndex, contentItems, segmentsLength, currentSegmentIndex]);

  useEffect(() => {
    if (!currentSegment) {
      setContentItems([]);
      return;
    }

    let items = [
      {
        type: "Summary",
        data: currentSegment.summary,
        start: timeInSeconds(currentSegment.start),
      },
      ...(currentSegment.goals || []).map((goal, index) => ({
        type: "Goal",
        data: { ...goal, step_number: index + 1 },
        start: goal.start
      })),
    ];

    // Assign default start times if missing
    for (let i = 0; i < items.length; i++) {
      if (items[i].start === undefined) {
        items[i].start = items[i - 1]
          ? items[i - 1].start + 10
          : timeInSeconds(currentSegment.start) + i * 10;
      }
    }

    // Calculate end times for items
    for (let i = 0; i < items.length; i++) {
      items[i].end = items[i + 1] ? items[i + 1].start : Infinity;
    }

    setContentItems(items);
  }, [currentSegment]);

  // Update currentContentIndex based on currentTime, unless manual navigation is in progress
  useEffect(() => {
    if (isManualNavigation.current) {
      // Skip updating currentContentIndex during manual navigation
      return;
    }

    if (contentItems.length > 0) {
      const currentItemIndex = contentItems.findIndex(
        (item) =>
          currentTime >= item.start && currentTime < (item.end || Infinity)
      );

      if (
        currentItemIndex !== -1 &&
        currentContentIndex !== currentItemIndex
      ) {
        setCurrentContentIndex(currentItemIndex);
      }
    }
  }, [currentTime, contentItems]);


  // Reset manual navigation flag after currentContentIndex changes
  useEffect(() => {
    if (isManualNavigation.current) {
      isManualNavigation.current = false;
    }
  }, [currentContentIndex]);

  const totalGoals = contentItems.filter((item) => item.type === "Goal").length + 1;

  // Handle Next action
  const handleNext = () => {
    triggerHint(); 
    if (currentContentIndex < contentItems.length - 1) {
      const nextIndex = currentContentIndex + 1;
      isManualNavigation.current = true;
      setCurrentContentIndex(nextIndex);
      const nextItem = contentItems[nextIndex];
      if (nextItem && nextItem.start !== undefined) {
        handleSeek(nextItem.start);
      }
    } else if (currentSegmentIndex < segmentsLength - 1) {
      // Move to the next segment
      const newSegmentIndex = currentSegmentIndex + 1;
      setCurrentSegmentIndex(newSegmentIndex);
      isManualNavigation.current = true;
      setCurrentContentIndex(0); // Start at the beginning of the next segment
      const nextSegmentStart = segments[newSegmentIndex].start;
      handleSeek(timeInSeconds(nextSegmentStart));
    } else {
      toastify.custom("You've completed the video. Great job!");
    }
  };

  // Handle Prev action
  const handlePrev = () => {
    triggerHint(); 
    if (currentContentIndex > 0) {
      const prevIndex = currentContentIndex - 1;
      isManualNavigation.current = true;
      setCurrentContentIndex(prevIndex);
      const prevItem = contentItems[prevIndex];
      if (prevItem && prevItem.start !== undefined) {
        handleSeek(prevItem.start);
      }
    } else if (currentSegmentIndex > 0) {
      // Move to the previous segment
      const newSegmentIndex = currentSegmentIndex - 1;
      setCurrentSegmentIndex(newSegmentIndex);
      isManualNavigation.current = true;
      setCurrentContentIndex(-1); // Will be corrected after contentItems update
      const prevSegmentStart = segments[newSegmentIndex].start;
      handleSeek(timeInSeconds(prevSegmentStart));
    } else {
      toastify.info("You are at the beginning of the video.");
    }
  };

  // Navigate to the previous section
  const handlePrevSection = () => {
    if (currentSegmentIndex > 0) {
      const prevSegmentIndex = currentSegmentIndex - 1;
      setCurrentSegmentIndex(prevSegmentIndex);
      setCurrentContentIndex(0); // Start at the beginning of the new section
      const prevSegmentStart = segments[prevSegmentIndex].start;
      handleSeek(timeInSeconds(prevSegmentStart));
    } else {
      toastify.info("You are at the first section.");
    }
  };

  // Navigate to the next section
  const handleNextSection = () => {
    if (currentSegmentIndex < segmentsLength - 1) {
      const nextSegmentIndex = currentSegmentIndex + 1;
      setCurrentSegmentIndex(nextSegmentIndex);
      setCurrentContentIndex(0); // Start at the beginning of the new section
      const nextSegmentStart = segments[nextSegmentIndex].start;
      handleSeek(timeInSeconds(nextSegmentStart));
    } else {
      toastify.info("You have reached the last section.");
    }
  };

  // Adjust currentContentIndex after contentItems update due to segment change
  useEffect(() => {
    if (currentContentIndex === -1 && contentItems.length > 0) {
      setCurrentContentIndex(contentItems.length - 1);
    }
  }, [contentItems, currentContentIndex]);

  if (!currentSegment) {
    return <EmptyInfo />;
  }

  return (
    <div
      id="video-info-container"
      className="flex flex-col items-center justify-center w-full h-full bg-white overflow-hidden"
    >
      {/* Content */}
      <div className="flex flex-col items-center justify-center w-full h-full relative">
        {/* Navigation Buttons */}
        <div
          className={`${
            source === "youtube"
              ? "flex justify-center absolute w-full top-4 space-x-6 z-50"
              : "flex justify-center fixed bottom-[8rem] w-full space-x-6 z-50"
          }`}
        >
          {/* Previous Button */}
          <button
            onClick={(e) => {
              handleClickEffect(e);
              handlePrev(); // Call your existing function
            }}
            className="bg-blue-500 hover:bg-blue-600 text-white py-3 px-4 rounded-full shadow-md focus:outline-none transition relative overflow-hidden"
            aria-label="Previous"
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>
  
          {/* Chatbot Button */}
          <div className="relative">
            <button
              onClick={() => setIsChatbotOpen(true)}
              className="bg-blue-500 p-2 rounded-full shadow-lg transition-transform duration-300 transform hover:scale-110 focus:outline-none"
              aria-label="Open Chatbot"
            >
              <img src={ChatbotLogo} alt="Chatbot Logo" className="w-8 h-8" />
            </button>

            {/* Enhanced Tooltip Below Chatbot Button */}
          <div
            className={`absolute top-full mt-3 left-1/2 transform -translate-x-1/2 bg-white border border-gray-300 shadow-md text-gray-700 text-sm rounded-md px-4 py-3 transition-opacity duration-300 ${
              showHint ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
            style={{ width: "14rem" }}
          >
            {/* Keyboard Icon Section */}
            <div className="flex items-center justify-center mb-3">
              <FontAwesomeIcon icon={faKeyboard} className="text-blue-500 text-lg" />
              <span className="ml-2 text-sm font-medium">Shortcuts</span>
            </div>

            <hr className="border-gray-300 my-2" />

            {/* Navigate Cards Section */}
            <div className="flex items-center mb-2 justify-center">
              <FontAwesomeIcon icon={faArrowLeft} className="text-blue-500" />
              <FontAwesomeIcon icon={faArrowRight} className="text-blue-500 mx-2" />
              <span>Navigate Cards</span>
            </div>

            {/* Navigate Sections Section */}
            <div className="flex items-center">
              <FontAwesomeIcon icon={faArrowUp} className="text-blue-500 ml-7" />
              <FontAwesomeIcon icon={faArrowDown} className="text-blue-500 mx-2" />
              <span>Navigate Sections</span>
            </div>
          </div>
          </div>

          {/* Next Button */}
          <button
            onClick={(e) => {
              handleClickEffect(e);
              handleNext(); // Call your existing function
            }}
            className="bg-blue-500 hover:bg-blue-600 text-white py-3 px-4 rounded-full shadow-md focus:outline-none transition relative overflow-hidden"
            aria-label="Next"
          >
            <FontAwesomeIcon icon={faArrowRight} size="lg" />
          </button>
        </div>
        
        {/* Feedback Button */}
        <div className="fixed bottom-4 right-4 z-50">
          <button
            className="bg-blue-500 text-white text-xs py-2 px-3 rounded-full shadow-md hover:bg-blue-600 focus:outline-none transition-all"
            onClick={handlePopupOpen}
          >
            <FontAwesomeIcon icon={faCommentDots} className="w-3 h-3 mr-1" />
            Give us Feedback
          </button>
        </div>
        
        {/* Feedback Modal */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className="bg-white p-6 rounded-lg shadow-lg w-96"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-lg font-semibold mb-4 text-gray-700">
              Share Your Feedback
            </h2>
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              className="w-full h-32 border border-gray-300 rounded-lg p-3 text-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              placeholder="Let us know what you think..."
            />
            <div className="flex justify-end space-x-3 mt-4">
              <button
                onClick={handlePopupClose}
                className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleFeedbackSubmit}
                className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ${
            feedbackSubmitting ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"
          }`}
              >
                {feedbackSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      )}
        {/* Other content */}
        <DesktopContent
          contentItems={contentItems}
          currentContentIndex={currentContentIndex}
          total_steps={totalGoals}
          setIsTranscriptCollapsed={setIsTranscriptCollapsed}
          handleCardNext={handleNext}
        />
      </div>
    </div>
  );
  

};

// EmptyInfo component will be rendered when there is no currentSegment
export const EmptyInfo = () => {
  return (
    <div
      id="video-info-container"
      className="w-full h-full bg-white flex items-center justify-center"
    >
      <p>No content available.</p>
    </div>
  );
};
