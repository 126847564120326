import React, { useState } from "react";
import { secondsToTime } from "../helper";
import { MdTextSnippet } from "react-icons/md"; // Importing a better icon

export const TranscriptSidebar = ({
  currentSegment,
  handleSeek,
  currentTime,
  isTranscriptCollapsed,
  setIsTranscriptCollapsed
}) => {
  if (!currentSegment) {
    return null;
  }

  const { condensed_transcript: transcripts } = currentSegment;

  return (
    <div
      className={`transition-all duration-300 ease-in-out ${
        isTranscriptCollapsed ? "w-14" : "w-[22rem]"
      } bg-white border-l border-gray-200 h-full flex flex-col items-center`}
    >
      {/* Toggle Button */}
      <div
        className={`flex flex-col items-center justify-center border-b border-gray-200 ${
          isTranscriptCollapsed ? "space-y-0" : "space-y-2"
        } border-b border-gray-200`}
      >
        <button
          onClick={() => setIsTranscriptCollapsed(!isTranscriptCollapsed)}
          className="focus:outline-none flex flex-col items-center space-y-1"
          aria-label={isTranscriptCollapsed ? "Expand Transcript" : "Collapse Transcript"}
        >
          <MdTextSnippet
            className={`text-3xl mb-1 ${
              isTranscriptCollapsed ? "text-blue-500" : "text-gray-600"
            }`}
          />
          {isTranscriptCollapsed && (
            <span className="text-[11px] text-blue-500 font-semibold leading-none">
              Transcript
            </span>
          )}
        </button>
      </div>
      {/* Transcript Content */}
      {!isTranscriptCollapsed && (
        <div className="overflow-y-auto p-2 w-full">
          {transcripts && transcripts.length > 0 ? (
            <ul>
              {transcripts.map((item, index) => {
                const isActive =
                  currentTime >= item.start &&
                  currentTime < (transcripts[index + 1]?.start || Infinity);
                return (
                  <li
                    key={index}
                    className={`mb-2 cursor-pointer p-1 rounded ${
                      isActive ? "bg-gray-200" : "hover:bg-gray-100"
                    }`}
                    onClick={() => handleSeek(item.start)}
                  >
                    <div className="text-sm text-gray-700">{item.content}</div>
                    <div className="text-xs text-gray-500">
                      {secondsToTime(item.start)}
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className="text-sm text-gray-500">No transcripts available.</p>
          )}
        </div>
      )}
    </div>
  );
};
