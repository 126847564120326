import React from "react";

const ChoiceModal = ({ setSelectedVersion }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-8 max-w-sm mx-auto">
        <h2 className="text-2xl font-bold mb-4">Choose Your Experience</h2>
        <p className="mb-6">Please select the version you want to view:</p>
        <div className="flex justify-between">
          <button
            onClick={() => setSelectedVersion("simple")}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Simple Version
          </button>
          <button
            onClick={() => setSelectedVersion("detailed")}
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
          >
            Detailed Version
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChoiceModal;
