export const StartingCardDesktop = () => {
  return (
    <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-xl shadow-lg w-full
    flex flex-col items-center justify-center text-center border border-blue-300 relative h-[75dvh] md:h-full">
      <p className="text-blue-700 font-semibold text-2xl tracking-wide mb-4">
        Welcome to the first section of the video!
      </p>
      <p className="text-gray-600 mt-2 text-base">
        Scroll up to begin your journey.
      </p>
      {/* Decorative element */}
      <div className="absolute top-4 right-4 bg-blue-200 w-6 h-6 rounded-full animate-pulse"></div>
      <div className="absolute bottom-4 left-4 bg-blue-200 w-6 h-6 rounded-full animate-pulse"></div>
    </div>
  );
};
