import React from "react";

export const GoalCard = ({
  total_steps,
  step_number,
  learning_goal,
  explanation,
}) => {
  return (
    <div className="transition-all duration-300 ease-in-out transform bg-gradient-to-b from-white to-gray-50 p-6 rounded-xl shadow-md flex flex-col items-start border-l-4 border-indigo-500 h-[75dvh] relative active:scale-95 active:shadow-md overflow-auto mx-3">
      {/* Icon for objectives */}
      <div className="text-indigo-500 mb-3">{/* Add an icon if desired */}</div>
      <p className="text-indigo-700 font-semibold text-lg tracking-wide mb-2">
        Learning Objective
      </p>
      <p className="text-gray-700 text-sm mb-2">
        Step {step_number} of {total_steps - 1}
      </p>
      {/* Highlighted Explanation Section */}
      <p className="text-gray-900 text-lg font-semibold leading-relaxed">
        {learning_goal}
      </p>
      <p className="text-gray-800 text-base font-medium mt-4 leading-relaxed">
        {explanation}
      </p>
      {/* Subtle background element */}
      <div className="absolute inset-0 rounded-xl bg-indigo-100 opacity-10 blur-md"></div>
    </div>
  );
};
